import { useEffect } from 'react'
import { type Toast } from '#app/utils/toast.server.ts'
import { toast as toaster } from 'react-hot-toast'

export function useToast(toast?: Toast | null) {
	useEffect(() => {
		if (toast) {
			setTimeout(
				() =>
					toaster.custom(t => (
						<div
							className={`${
								t.visible ? 'animate-enter' : 'animate-leave'
							} pointer-events-auto flex w-full max-w-md rounded-lg bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5`}
						>
							<div className="w-0 flex-1 p-4">
								<div className="flex items-start">
									<div className="ml-3 flex-1">
										<p className="text-sm font-bold text-emerald-400">{toast.title}</p>
										<p className="mt-1 text-sm font-medium text-emerald-400">{toast.description}</p>
									</div>
								</div>
							</div>
							<div className="flex border-l border-gray-200">
								<button
									onClick={() => toaster.dismiss(t.id)}
									className="rounded-none flex w-full items-center justify-center rounded-r-lg border border-transparent p-4 text-sm font-medium text-emerald-400 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500"
								>
									Close
								</button>
							</div>
						</div>
					), {
						duration: 3000,
					}),
				0,
			)
		}
	}, [toast])
}
